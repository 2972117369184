<template>
  <div class="staffManage">
    <ds-header title="人员管理"></ds-header>
    <div class="main-wrap">
      <!-- 左侧部门列表 -->
      <div class="main-left">
        <a-tree
          :tree-data="departmentList"
          :replaceFields="{ children: 'childList', title: 'organizationName', key: 'organizationId' }"
          @select="selectedDepartment"
          :selectedKeys="[searchForm.organizationId]"
        >
          <template #title="{ organizationId, organizationName,userName,principalId,phoneNumber,account,openId
 }">
            <a-dropdown :trigger="['contextmenu']">
              <div class="department-container">
                <a-tooltip placement="right">
                  <template slot="title">
                    {{ organizationName }}
                  </template>
                  <div class="department-name">{{ organizationName }}</div>
                </a-tooltip>
                <div>
                  <a-button type="link" size="small" style="padding-right: 0" @click="addDepartment(organizationId)">
                    <a-icon type="plus-circle"
                  /></a-button>
                  <a-button type="link" size="small" style="padding-right: 0" @click="deleteDepartment(organizationId)">
                    <a-icon type="minus-circle"
                  /></a-button>
                  <a-button
                    type="link"
                    size="small"
                    style="padding-right: 0"
                    @click="editDepartment(organizationId, organizationName,userName,principalId,phoneNumber,account,openId)"
                  >
                    <a-icon type="edit"
                  /></a-button>
                </div>
              </div>
            </a-dropdown>
          </template>
        </a-tree>
      </div>
      <!-- 右侧搜索表单、人员列表 -->
      <div class="main-right" style="margin-top: 15px; padding-right: 15px">
        <div class="tableClass">
          <a-form layout="inline">
            <a-form-item label="账号：">
              <a-input v-model.trim="searchForm.account" placeholder="请输入账号" class="inputClass" />
            </a-form-item>
            <a-form-item label="姓名：">
              <a-input v-model.trim="searchForm.userName" placeholder="请输入姓名" class="inputClass" />
            </a-form-item>
            <a-form-item label="手机号：">
              <a-input v-model.trim="searchForm.phoneNumber" placeholder="请输入手机号" class="inputClass" />
            </a-form-item>
            <a-form-item label="角色：">
              <a-select
                v-model="searchForm.roleTypeId"
                style="width: 200px"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option v-for="item in roleTypeList" :value="item.roleTypeId" :key="item.roleTypeId">{{
                  item.roleTypeName
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
          <div class="btn-class">
            <a-button type="primary" @click="addStaff"> <a-icon type="plus" /> 新增人员</a-button>
            <div>
              <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
              <a-button style="margin-left: 15px" @click="resetSearchForm"> <a-icon type="redo" />重置 </a-button>
            </div>
          </div>
          <div style="padding-top: 15px; overflow: auto">
            <a-table
              :rowKey="(record, index) => index"
              :columns="table.columns"
              :data-source="table.dataInfo"
              :pagination="table.pagination.total ? table.pagination : false"
              @change="pageChange"
              bordered
            >
              <span slot="userName" slot-scope="text" class="action">
                <a-tooltip>
                  <template slot="title">
                    {{ text }}
                  </template>
                  <div class="omit-text" style="width: 100px">{{ text }}</div>
                </a-tooltip>
              </span>
              <span slot="organization" slot-scope="text" class="action">
                <a-tooltip>
                  <template slot="title">
                    {{ text }}
                  </template>
                  <div class="omit-text" style="width: 200px">{{ text }}</div>
                </a-tooltip>
              </span>
              <span slot="projectNameStr" slot-scope="text" class="action">
                <a-tooltip>
                  <template slot="title">
                    {{ text }}
                  </template>
                  <div class="omit-text" style="width: 280px">{{ text }}</div>
                </a-tooltip>
              </span>
              <div slot="role" slot-scope="text" class="action">
                <div class="role-list-container">
                  <div v-for="(item, index) in text.split('，')" :key="index">{{ item }}</div>
                </div>
              </div>
              <span slot="action" slot-scope="text, record" class="action">
                <a-button class="btnClass" type="link" @click="editStaff(record)"> 编辑 </a-button>
                <a-button class="btnClass" style="color: #f5222d" type="link" @click="deleteStaff(record)">
                  删除
                </a-button>
              </span>
            </a-table>
          </div>
        </div>
      </div>
    </div>
    <AddDepartment
      v-if="showAddDepartment"
      :show-add-department.sync="showAddDepartment"
      :parentOrganizationId="addDepartmentParentOrganizationId"
      @refreshDepartmentList="refreshDepartmentList"
    />
    <EditDepartment
      v-if="showEditDepartment"
      :show-edit-department.sync="showEditDepartment"
      :editInfo="editDepartmentDataInfo"
      @refreshDepartmentList="refreshDepartmentList"
    />
    <AddStaff
      v-if="showAddStaff"
      :show-add-staff.sync="showAddStaff"
      @refreshStaffList="refreshStaffList"
      :listSelectedDepartment="searchForm.organizationId"
    />
    <EditStaff
      v-if="showEditStaff"
      :show-edit-staff.sync="showEditStaff"
      @refreshStaffList="refreshStaffList"
      :userId="userId"
    />
  </div>
</template>

<script>
import * as api from '@/api/demandPoolShunyi';
import { SUCCESS_CODE } from '@/config';
import { staffTableConfig, staffSearchForm } from '../help';
import AddDepartment from './components/addDepartment';
import EditDepartment from './components/editDepartment';
import AddStaff from './components/addStaff';
import EditStaff from './components/editStaff';
import { mapState } from 'vuex';
export default {
  name: 'staffManage',
  components: {
    AddDepartment,
    EditDepartment,
    AddStaff,
    EditStaff,
  },
  computed: {
    ...mapState({
      roleTypeList: (state) => [{ roleTypeId: '', roleTypeName: '全部' }].concat(state.demandPool.roleTypeList),
    }),
  },
  data() {
    return {
      departmentList: [], // 部门集合
      showAddDepartment: false, // 显示添加部门
      addDepartmentParentOrganizationId: '', // 添加部门上级id
      showEditDepartment: false, // 显示编辑部门
      editDepartmentDataInfo: {}, // 编辑部门信息
      showAddStaff: false, // 显示添加人员
      userId: '', // 用户id, 查询详情是用
      showEditStaff: false, // 显示编辑人员
      // 搜索表单
      searchForm: {
        ...staffSearchForm,
      },
      table: staffTableConfig, // 人员列表配置
    };
  },
  methods: {
    // 添加部门
    addDepartment(organizationId) {
      this.showAddDepartment = true;
      this.addDepartmentParentOrganizationId = organizationId;
    },
    // 删除部门
    deleteDepartment(organizationId) {
      const self = this;
      this.$confirm({
        title: '确认删除组织',
        content: '删除后此数据将无法恢复, 确定要删除吗',
        onOk() {
          self.deleteDepartmentRequest(organizationId);
        },
        onCancel() {},
      });
    },
    // 编辑部门
    editDepartment(organizationId, organizationName,userName,principalId,phoneNumber,account,openId) {
     console.log(userName,principalId,phoneNumber,account,openId);
      this.showEditDepartment = true;
      this.editDepartmentDataInfo = {
        organizationId,
        organizationName,
        userName,principalId,phoneNumber,account,openId
      };
    },
    // 选中部门
    selectedDepartment(item) {
      if (item.length) {
        this.table.pagination.current = 1;
        const organizationId = item[0];
        if (organizationId !== this.searchForm.organizationId) {
          this.searchForm.organizationId = organizationId;
          // 通过条件搜索与点击部门搜索互斥
          this.searchForm = {
            ...this.searchForm,
            account: '',
            userName: '',
            phoneNumber: '',
            roleTypeId: '',
          };
          this.getStaffList();
        }
      }
    },
    // 刷新部门列表
    refreshDepartmentList() {
      this.getDepartmentList();
    },
    // 刷新人员列表
    refreshStaffList() {
      this.getStaffList();
    },
    //查询
    search() {
      this.table.pagination.current = 1;
      // 通过条件搜索与点击部门搜索互斥
      this.searchForm = {
        ...this.searchForm,
        organizationId: '',
      };
      this.getStaffList();
    },
    //重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      const organizationId = this.searchForm.organizationId;
      this.searchForm = { ...staffSearchForm, organizationId };
      this.getStaffList();
    },
    // 添加人员
    addStaff() {
      this.showAddStaff = true;
    },
    // 编辑人员
    editStaff(item) {
      this.userId = item.userId;
      this.showEditStaff = true;
    },
    // 删除人员
    deleteStaff(item) {
      const self = this;
      this.$confirm({
        title: '确认删除人员',
        content: '删除后此数据将无法恢复, 确定要删除吗',
        onOk() {
          self.deleteStaffRequest(item.userId);
        },
        onCancel() {},
      });
    },
    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getStaffList();
    },
    /**网络请求 */
    // 获取部门列表
    async getDepartmentList() {
      try {
        const result = await api.getDepartmentListForDemandPool();
        if (result.code === SUCCESS_CODE) {
          this.departmentList = result.data;
          if (this.departmentList.length) {
            this.searchForm.organizationId = this.departmentList[0].organizationId;
          }
          this.getStaffList();
          this.$store.commit('demandPool/UPDATE_DEPARTMENT_LIST_STATE', this.departmentList);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 删除部门
    async deleteDepartmentRequest(organizationId) {
      try {
        const result = await api.deleteDepartmentForDemandPool(organizationId);
        if (result.code === SUCCESS_CODE) {
          this.$message.success(result.msg);
          this.getDepartmentList();
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {}
    },
    // 获取人员列表
    async getStaffList() {
      try {
        const result = await api.getStaffListForDemandPool({
          ...this.searchForm,
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
        });
        if (result.code === SUCCESS_CODE) {
          this.table.dataInfo = result.data.list;
          this.table.pagination.total = result.data.total;
        }
      } catch (error) {}
    },
    // 删除人员
    async deleteStaffRequest(userId) {
      try {
        const result = await api.deleteStaffForDemandPool(userId);
        if (result.code === SUCCESS_CODE) {
          this.$message.success(result.msg);
          this.getStaffList();
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {}
    },
  },
  created() {
    this.getDepartmentList();
    this.$store.dispatch('demandPool/getRoleTypeList');
  },
  mounted() {
    let handleResize = ()=> {
      let width = document.getElementsByClassName('staffManage')[0].clientWidth;
      console.log(width,'=====');
      if(width>1930){
        this.table.columns[this.table.columns.length-1].fixed = '';
      }else{
        this.table.columns[this.table.columns.length-1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()
  },
};
</script>

<style lang="scss" scoped>
.omit-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.role-list-container {
  display: flex;
  flex-direction: column;
}
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.main-wrap {
  display: flex;
  .main-left {
    overflow-x: auto;
    // width: 15%;
    width: 250px;
    .department-container {
      display: flex;
      .department-name {
        width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .main-right {
    // width: 85%;
    width: calc(100% - 250px);
  }
  .btn-class {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}
.icon-btns {
  color: #1890ff;
  padding-left: 4px;
}
.mx-4 {
  margin: 0 4px;
}
.my-20 {
  margin: 20px 0;
}
.safe-w {
  max-width: 220px;
}
.tableClass {
  margin-left: 10px;
  overflow: auto;
}
::v-deep .ant-table-body {
  overflow: auto;
}
</style>
